import request from '@/utils/request'

// 查询展会剧本列表
export function listExpoScript(query) {
  return request({
    url: '/expo/expo/script/list',
    method: 'get',
    params: query
  })
}
// 查询展会列表
export function listExpoSc(query) {
  return request({
    url: '/expo/expo/listScript',
    method: 'get',
    params: query
  })
}
// 查询展会剧本详细
export function getExpoScript(id) {
  return request({
    url: '/expo/expo/script/' + id,
    method: 'get'
  })
}

// 新增展会剧本
export function addExpoScript(data) {
  return request({
    url: '/expo/expo/script',
    method: 'post',
    data: data
  })
}

// 批量新增展会剧本
export function addExpoScriptBatch(data) {
  return request({
    url: '/expo/expo/script/batch',
    method: 'post',
    data: data
  })
}

// 修改展会剧本
export function updateExpoScript(data) {
  return request({
    url: '/expo/expo/script',
    method: 'put',
    data: data
  })
}

// 删除展会剧本
export function delExpoScript(id) {
  return request({
    url: '/expo/expo/script/' + id,
    method: 'delete'
  })
}
